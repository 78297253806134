<template>
    <div class="contentCenter bg-white py-4 px-2" style="max-width: 500px;margin: 0 auto;">
        <div>
            <div class="m-6 mb-7 mt-3">
                <div class="text-center mb-2 align-items-center">
                    <img width="100" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/permission.png">
                    <h4 class="fw-500 digi-black mt-3">Alert</h4>
                    <div class="access-denied mt-6 pt-2 pb-2 pl-7 pr-7 d-flex justify-content-center align-items-center">
                        <div class="mt-2">
                            <i style="font-size:35px" class="icon icon-close text-red font-weight-bold"></i>
                        </div>
                        <div class="fs-18 font-weight-bold text-secondary ml-4">
                            Access Denied!
                        </div>
                    </div>
                    <p class="fw-500 fs-17 mt-8 digi-black">Hey Champ! Fortunately, you can go to :</p>
                    <div class="d-flex align-items-center justify-content-center">
                        <h6 class="fw-500 mt-5 ml-2 digi-black">Contact the Administrator
                            <i class="icon icon-information text-secondary fs-16"></i>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    methods:{
    },
}
</script>
<style scoped>
.text-area.form-control{
    height: 100px !important;
}
.w-15{
    width: 18%;
}
.digi-black{
    color: #435764;
}
.access-denied{
    border-radius: 3.5px;
    box-shadow: 1.5px 2px 5px 2.5px rgba(0, 0, 0, 0.16);
    background-color: var(--white);
    margin: 0 auto;
}
.fs-17{
    font-size: 17px;
}
.crm-button{
    height: 23px;
    line-height: 22px;
    border-radius: 3px;
    width: 75px;
}
</style>